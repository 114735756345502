<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg mt-3 mt-lg-0">
                <div class="row">
                  <div v-if="client.profile_picture" class="col-auto my-auto">
                    <div class="avatar avatar-xl position-relative">
                      <img
                        :src="client.profile_picture"
                        alt="profile_image"
                        class="shadow-sm w-100 border-radius-lg"
                      />
                    </div>
                  </div>
                  <div class="col-auto my-auto">
                    <div class="h-100">
                      <span class="">{{ "Client Name : " }}</span>
                      <span class="font-weight-bold">{{ client.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-auto text-lg-end mt-3 mt-lg-0">
                <argon-button
                  v-if="orderCanBeRefunded"
                  class="me-3 mb-0"
                  @click="showModalObject(modals.refundRequest)"
                  >Refund Request</argon-button
                >
                <submit-form-button
                  v-if="orderDetails.status === 'draft'"
                  :form-submitted="formSubmitted"
                  default-button-text="Publish Order"
                  default-class="btn btn-success mb-0 me-3 "
                  form-submitted-class="btn btn-dark mb-0 me-3"
                  @click="publishOrder"
                />
                <argon-button
                  v-if="orderCanBeAmended"
                  class="me-3 mb-0"
                  @click="showModalObject(modals.bulkFreezeDelivery)"
                  >Bulk Freeze</argon-button
                >
                <submit-form-button
                  :form-submitted="formSubmitted"
                  default-button-text="Download"
                  default-class="btn btn-success mb-0 me-3 "
                  form-submitted-class="btn btn-dark mb-0 me-3"
                  @click="downloadOrder"
                />
                <argon-button
                  v-if="orderCanBeAmended && orderDetails.type === 'monthly'"
                  class="me-3 mb-0"
                  @click="showModalObject(modals.updateOffDays)"
                  >Update Off Days</argon-button
                >
                <router-link
                  v-if="canReplaceVariations"
                  :to="{
                    name: 'Replace Variations Order',
                    params: {
                      clientId: client.id,
                      orderId: orderDetails.id,
                    },
                  }"
                  class="btn btn-success me-3 mb-0"
                >
                  Replace Variations
                </router-link>
                <router-link
                  v-if="$can('view', 'clients')"
                  :to="{
                    name: 'Client Profile',
                    params: { id: client.id },
                  }"
                  class="btn btn-white text-success mb-0"
                >
                  Back
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-show="!loading.events && !loading.orderDetails"
        class="col-12 col-md-6 mt-4"
      >
        <div
          class="card widget-calendar widget-calendar-week h-100 order-calendar"
        >
          <div class="p-3 card-body">
            <full-calendar
              v-if="showFullCalendar"
              id="calendar-client-plan"
              ref="fullCalendar"
              :options="calendarOptions"
            >
              <template #eventContent="info">
                {{ info.event.title }}
                <dislikes-allergens-icons
                  :item="info.event.extendedProps.dislikesAllergens"
                />
              </template>
            </full-calendar>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mt-3">
                <h6 class="mb-0">{{ cardTitle("Menu") }}</h6>
                <RecipeMacrosBadge
                  v-if="menuItems.length > 0"
                  class="col-lg mt-3 mt-lg-0"
                  :calories="dailyMacros.calories"
                  :carbs="dailyMacros.carbs"
                  :fat="dailyMacros.fat"
                  :protein="dailyMacros.protein"
                />
                <h6
                  v-if="currentDayPack.delivery_address_id && deliveryTime"
                  class="mt-3"
                >
                  Delivery to {{ deliveryAddressText }} in the
                  {{ deliveryTime }}
                </h6>
                <div>Order type: {{ orderDetails.type }}</div>
                <router-link
                  v-if="orderDetails.meal_template_id"
                  class="mt-1"
                  :to="{
                    name: 'Edit Meal Template',
                    params: { id: orderDetails.meal_template_id },
                  }"
                  >Meal template used:
                  {{ orderDetails.meal_template_name }}</router-link
                >
                <div v-if="currentDayPack.free_delivery" class="text-success">
                  <i class="fa fa-truck"></i> Free delivery. Reason:
                  {{ currentDayPack.free_delivery_reason }}
                </div>
              </div>
              <div class="col-12 mt-3">
                <argon-button
                  v-if="showUnfreezeButton"
                  class="ms-3 mb-3"
                  @click="showModalObject(modals.unfreeDeliveryModal)"
                  >Unfreeze</argon-button
                >
                <argon-button
                  v-if="showFreezeButton"
                  class="ms-3 mb-3"
                  @click="freezeDelivery"
                  >Freeze</argon-button
                >
                <argon-button
                  v-if="showFreezeButton"
                  class="ms-3 mb-3"
                  @click="freezeAndMoveDelivery"
                  >Freeze and move</argon-button
                >
                <argon-button
                  v-if="showFreezeButton"
                  class="ms-3 mb-3"
                  @click="showModalObject(modals.freezeAndRescheduleDelivery)"
                  >Freeze and reschedule</argon-button
                >
                <argon-button
                  v-if="
                    orderCanBeAmended &&
                    selectedDate < today &&
                    $can('update', 'compensate_deliveries')
                  "
                  class="ms-3 mb-3"
                  @click="showModalObject(modals.compensateDelivery)"
                  >Compensate</argon-button
                >
                <argon-button
                  v-if="
                    orderCanBeAmended &&
                    selectedDate >= nextAvailableStartDate &&
                    currentDayPack.delivery_address_id
                  "
                  class="ms-3 mb-3"
                  @click="showModalObject(modals.updateDeliveryAddress)"
                  >Update delivery address</argon-button
                >
                <argon-button
                  v-if="showSubmitExceptionButton"
                  class="ms-3 mb-3"
                  @click="showModalObject(modals.deliveryAddressException)"
                  >Submit exception</argon-button
                >
                <argon-button
                  v-if="showCancelDeliveryButton"
                  class="ms-3 mb-3"
                  @click="cancelDelivery"
                  >Cancel Delivery</argon-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <order-inactive-meals-card-new
        v-if="inactiveMeals.length > 0"
        class="mt-4"
        :inactive-meals="inactiveMeals"
        @edit-menu-item="showOrderMenuModal"
        @delete-menu-item="handleMenuDeleteNew"
      />

      <div v-if="quickViewEnabled && !showFood" class="col-12 mt-4">
        <div class="card">
          <div class="card-body">
            <argon-button @click="handleShowFood"> Show Food </argon-button>
          </div>
        </div>
      </div>
      <div v-if="showFood || !quickViewEnabled" class="col-12">
        <div v-if="showDayPack" class="row">
          <div class="col-12">
            <template v-if="!loading.menu">
              <div class="row">
                <div
                  v-for="(meal, key) in mealTypes"
                  :key="key"
                  class="col-12 col-md-6 mt-4"
                >
                  <div class="card h-100">
                    <div class="card-header pb-0">
                      <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                          <h6 class="mb-0 text-capitalize">
                            {{ mealTypeNames[meal.meal_type] }}
                          </h6>
                        </div>
                        <div
                          v-if="showMenuItemActionButtons"
                          class="col-md-4 text-end"
                        >
                          <button
                            class="mb-0 btn btn-xs bg-gradient-success"
                            @click="
                              showOrderMenuModal({
                                mealType: meal.meal_type,
                                actionType: 'Add',
                              })
                            "
                          >
                            <i class="fas fa-plus pe-2" aria-hidden="true"></i>
                            Add
                            {{ mealTypeNames[meal.meal_type] }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <template
                        v-for="(menuItem, index) of menuItemsGroup[
                          meal.meal_type
                        ]"
                        :key="index"
                      >
                        <template v-if="menuItem">
                          <menu-item
                            :id="`${menuItem.meal_type}-${index}`"
                            :menu-item="menuItem"
                            :meal-type="mealTypeNames[meal.meal_type]"
                            :show-action-buttons="showMenuItemActionButtons"
                            @edit-menu-item="showOrderMenuModal"
                            @delete-menu-item="handleMenuDelete"
                          />
                        </template>
                      </template>
                    </div>
                    <div
                      v-if="amendments[selectedDate][meal.meal_type].length > 0"
                      class="card-body"
                    >
                      <amendment-list
                        :title="`${
                          mealTypeNames[meal.meal_type]
                        } Amendments on ${dateFormat(selectedDate, 'Do MMMM')}`"
                        :amendments="amendments[selectedDate][meal.meal_type]"
                        :amendment-date="selectedDate"
                        @delete="handleAmendmentDelete($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <loading-spinner
        :loading="loading.events || loading.orderDetails || formSubmitted"
        :screen-center="true"
      />
      <div v-if="orderCanBeAmended" class="col-12 mt-4">
        <amendments-summary
          class="h-100"
          :meal-type-names="mealTypeNames"
          :amendments="amendments"
          :client-id="client.id"
          :order-id="orderDetails.id"
          @delete="handleAmendmentDelete($event)"
          @save="handleAmendmentsSave($event)"
        />
      </div>
      <div class="col-12 mt-4">
        <invoices-card
          title="Invoices"
          :initial-filter="{ order_id: orderDetails.id }"
          :show-filter="{ client_id: false, order_id: false }"
        />
      </div>
    </div>
  </div>

  <order-menu-modal
    v-if="modals.orderMenu.show"
    :modal-id="modals.orderMenu.id"
    :pack-id="packs.id"
    :order-id="orderDetails.id"
    :delivery-date="selectedDate"
    :client-id="client.id"
    :order-can-be-amended="orderCanBeAmended"
    :order-can-be-updated="orderCanBeUpdated"
    :initial-data="modals.orderMenu.data"
    :form-submitted="formSubmitted"
    @show-event="showEvent(selectedEvent)"
    @save="handleMenuChange"
    @close="closeModalObject(modals.orderMenu)"
  />
  <compensate-delivery-modal
    v-if="modals.compensateDelivery.show"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :day-pack-id="packs.id"
    @close="closeModalObject(modals.compensateDelivery)"
    @change="setOrder"
  />
  <freeze-reschedule-delivery-modal
    v-if="modals.freezeAndRescheduleDelivery.show"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :day-pack-id="packs.id"
    @close="closeModalObject(modals.freezeAndRescheduleDelivery)"
    @saved="setOrder"
  />
  <bulk-freeze-delivery-modal
    v-if="modals.bulkFreezeDelivery.show"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :day-pack-id="packs.id"
    :next-available-start-date="nextAvailableStartDate"
    @close="closeModalObject(modals.bulkFreezeDelivery)"
    @saved="setOrder"
  />
  <unfreeze-delivery-modal
    v-if="modals.unfreeDeliveryModal.show"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :day-pack-id="packs.id"
    @close="closeModalObject(modals.unfreeDeliveryModal)"
    @saved="setOrder"
  />
  <update-delivery-address
    v-if="modals.updateDeliveryAddress.show"
    :modal-id="modals.updateDeliveryAddress.id"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :day-pack-id="packs.id"
    :addresses="client.addresses"
    @close="closeModalObject(modals.updateDeliveryAddress)"
    @saved="setOrder"
  />
  <update-off-days-modal
    v-if="modals.updateOffDays.show"
    :modal-id="modals.updateOffDays.id"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :off-days="orderDetails.monthly_prep_off_days"
    @close="closeModalObject(modals.updateOffDays)"
    @saved="setOrder"
  />
  <delivery-address-modal
    v-if="modals.deliveryAddressException.show"
    :modal-id="modals.deliveryAddressException.id"
    title="Add delivery address exception"
    :show-area-timing="true"
    @close="closeModalObject(modals.deliveryAddressException)"
    @submit-address="createDeliveryAddressException"
  />
  <refund-request-modal
    v-if="modals.refundRequest.show"
    :modal-id="modals.refundRequest.id"
    :client-id="client.id"
    :order-id="orderDetails.id"
    :initial-data="modals.refundRequest.data"
    dialog-class="modal-xl"
    @saved="refundRequestSaved"
    @close="closeModalObject(modals.refundRequest)"
  />
</template>

<script>
import InvoicesCard from "@/views/pages/Invoices/components/ListTable.vue";
import MenuItem from "./components/MenuItem.vue";
import AmendmentList from "@/views/pages/Clients/components/AmendmentList.vue";
import RecipeMacrosBadge from "@/components/RecipeMacrosBadge";
import LoadingSpinner from "@/components/LoadingSpinner";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { getEventBgColor } from "@/lib/fullCalendarHelper";
import {
  dateFormat,
  downloadFile,
  handleError,
  handleResponse,
} from "@/lib/helpers";
import API from "@/services/api";
import { showMessage } from "@/assets/js/show-message";
import OrderMenuModal from "./components/OrderMenuModal.vue";
import profileImage from "@/assets/img/user-profile-icon.svg";
import AmendmentsSummary from "@/views/pages/Clients/components/AmendmentsSummary.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import apiOrders from "@/services/apiOrders";
import ArgonButton from "@/components/ArgonButton.vue";
import { closeModalObject, showModal, showModalObject } from "@/lib/bootstrap";
import CompensateDeliveryModal from "@/views/pages/Clients/components/CompensateDeliveryModal.vue";
import apiClientOrderDayPackController from "@/services/apiClientOrderDayPackController";
import FreezeRescheduleDeliveryModal from "@/views/pages/Clients/components/FreezeRescheduleDeliveryModal.vue";
import UpdateDeliveryAddress from "@/views/pages/Clients/components/UpdateDeliveryAddress.vue";
import DeliveryAddressModal from "@/views/pages/Clients/components/AddressModal.vue";
import apiClientOrder from "@/services/apiClientOrder";
import { deduplicateBy } from "@/lib/arrayHelper";
import DislikesAllergensIcons from "@/components/DislikesAllergensIcons.vue";
import { isDateBetween } from "@/lib/dateHelper";
import OrderInactiveMealsCardNew from "@/views/pages/Clients/components/OrderInactiveMealsCardNew.vue";
import RefundRequestModal from "@/views/pages/Clients/components/RefundRequestModal.vue";
import apiMiscList from "@/services/apiMiscList";
import UnfreezeDeliveryModal from "@/views/pages/Clients/components/UnfreezeDeliveryModal.vue";
import UpdateOffDaysModal from "@/views/pages/Clients/components/UpdateOffDaysModal.vue";
import BulkFreezeDeliveryModal from "@/views/pages/Clients/components/BulkFreezeDeliveryModal.vue";

export default {
  name: "ClientOrder",
  components: {
    BulkFreezeDeliveryModal,
    UpdateOffDaysModal,
    UnfreezeDeliveryModal,
    RefundRequestModal,
    DislikesAllergensIcons,
    DeliveryAddressModal,
    UpdateDeliveryAddress,
    FreezeRescheduleDeliveryModal,
    CompensateDeliveryModal,
    ArgonButton,
    SubmitFormButton,
    AmendmentsSummary,
    RecipeMacrosBadge,
    OrderMenuModal,
    LoadingSpinner,
    MenuItem,
    AmendmentList,
    InvoicesCard,
    FullCalendar,
    OrderInactiveMealsCardNew,
  },
  data() {
    return {
      currentDayPack: {},
      profileImage,
      client: {
        id: parseInt(this.$route.params.id),
        name: "",
        profile_picture: "",
        addresses: [],
      },
      loading: {
        menu: true,
        menuModal: true,
        events: true,
        orderDetails: true,
      },
      dailyMacros: {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      },

      orderDetails: {
        id: parseInt(this.$route.params.orderId),
      },
      orderAllergensDislikes: [],
      menuItems: [],
      mealTypes: [],
      menuOrder: {},
      inactiveMeals: [],
      menuItemsGroup: [],
      mealTypeNames: {
        breakfast: "Breakfast",
        custom_meal: "Custom Meal",
        snacks: "Snacks",
        signature: "Signature",
        drinks: "Drinks",
      },
      amendments: {},
      formSubmitted: false,
      today: dateFormat("today", "YYYY-MM-DD"),
      events: [],
      selectedDate: "",
      initialEventId: 0,
      weekStartDate: "",
      weekEndDate: "",
      weekEvents: [],
      selectedEvent: {},
      packs: {
        id: 0,
        date: dateFormat("today", "YYYY-MM-DD"),
      },
      modals: {
        compensateDelivery: {
          id: "compensate-delivery-modal",
          show: false,
        },
        unfreeDeliveryModal: {
          id: "unfreeze-delivery-modal",
          show: false,
        },
        freezeAndRescheduleDelivery: {
          id: "freeze-reschedule-delivery-modal",
          show: false,
        },
        bulkFreezeDelivery: {
          id: "bulk-freeze-delivery-modal",
          show: false,
        },
        updateDeliveryAddress: {
          id: "update-delivery-address-modal",
          show: false,
        },
        deliveryAddressException: {
          id: "delivery-address-exception-modal",
          show: false,
        },
        orderMenu: {
          show: false,
          id: "menu-modal",
        },
        refundRequest: {
          show: false,
          id: "refund-request-modal",
          data: {},
        },
        updateOffDays: {
          show: false,
          id: "update-off-days-modal",
        },
      },
      calendarOptions: {
        firstDay: 1,
        contentHeight: "auto",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        showNonCurrentDates: false,
        selectable: true,
        editable: true,
        eventClick: this.eventClicked,
        datesSet: this.datesSet,
        dateClick: this.dateClicked,
        customButtons: {
          customToday: {
            text: "today",
            click: () => {
              this.calendarApi.gotoDate(this.today);
              this.showDate(this.today);
            },
          },
          toggleQuickView: {
            text: "Classic View",
            click: () => {
              this.quickViewEnabled = !this.quickViewEnabled;
              const button = document.querySelector(
                ".fc-toggleQuickView-button"
              );
              if (button) {
                button.classList.toggle("opacity-5", !this.quickViewEnabled);
              }
              this.setOrder();
            },
          },
        },
        headerToolbar: {
          start: "title",
          center: "",
          end: "customToday prev,next toggleQuickView",
        },
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric",
            },
          },
          // showNonCurrentDates: false,
        },
      },
      showFullCalendar: false,
      nextAvailableStartDate: dateFormat("+100 days", "YYYY-MM-DD"),
      cutOffDate: dateFormat("today", "YYYY-MM-DD"),
      quickViewEnabled: true,
      showFood: false,
      showDayActionButtons: false,
    };
  },
  computed: {
    showMenuItemActionButtons() {
      return this.selectedDate >= this.nextAvailableStartDate;
    },
    canReplaceVariations() {
      return (
        this.$ability.can("update", "unpaid_order_replace_items") &&
        (this.orderDetails.invoice?.data.status === "draft" ||
          this.orderDetails.invoice?.data.status === "unpublished") &&
        this.orderDetails.status !== "cancelled" &&
        this.orderDetails.status !== "completed" &&
        this.orderDetails.status !== "processing"
      );
    },
    showFreezeButton() {
      return (
        this.currentDayPack.delivery_status &&
        this.currentDayPack.delivery_status !== "freezed" &&
        this.orderCanBeAmended &&
        this.selectedDate >= this.nextAvailableStartDate
      );
    },
    showCancelDeliveryButton() {
      return (
        this.currentDayPack.delivery_status &&
        this.currentDayPack.delivery_status !== "cancelled" &&
        this.currentDayPack.delivery_status !== "freezed" &&
        this.orderCanBeAmended &&
        this.today < this.selectedDate &&
        this.today >= this.cutOffDate
      );
    },
    showUnfreezeButton() {
      return (
        this.$ability.can("update", "unfreeze_order_deliveries") &&
        this.currentDayPack.delivery_status === "freezed" &&
        this.orderCanBeAmended
      );
    },
    showSubmitExceptionButton() {
      return (
        this.orderCanBeAmended &&
        this.currentDayPack.delivery_status &&
        this.selectedDate < this.nextAvailableStartDate &&
        this.selectedDate > this.today
      );
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },

    getEventBgColor,
    orderCanBeRefunded() {
      const allowedStatuses = ["processing", "completed"];
      return (
        this.orderDetails && allowedStatuses.includes(this.orderDetails.status)
      );
    },
    orderCanBeAmended() {
      const allowedStatuses = ["processing", "completed"];
      return (
        this.orderDetails && allowedStatuses.includes(this.orderDetails.status)
      );
    },
    orderCanBeUpdated() {
      const allowedStatuses = ["draft", "initiated"];
      return (
        this.orderDetails && allowedStatuses.includes(this.orderDetails.status)
      );
    },
    deliveryAddressText() {
      const item = this.client.addresses.find(
        (item) => item.id === this.currentDayPack.delivery_address_id
      );
      if (item) {
        return item.nickname;
      }
      return "Unknown";
    },
    deliveryTime() {
      return this.currentDayPack.delivery_schedule;
    },

    dayPacks() {
      if (this.quickViewEnabled) {
        return this.orderDetails.dayPacksList?.data || [];
      }
      return this.orderDetails.dayPacks?.data || [];
    },

    showDayPack() {
      return !this.loading.events;
    },
  },
  watch: {
    selectedDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.setCutOffDate();
      }
    },
  },
  async mounted() {
    await this.setNextAvailableStartDate();
    await this.setClient();
    await this.setOrder();
    await this.fetchInactiveMeals();
  },
  methods: {
    showModal,
    closeModalObject,
    showModalObject,
    dateFormat,
    async setNextAvailableStartDate() {
      const response = await apiMiscList
        .nextAvailableStartDate()
        .catch(handleError);
      const data = await handleResponse(response);
      this.nextAvailableStartDate = data.date || this.nextAvailableStartDate;
    },
    async setCutOffDate() {
      const response = await apiMiscList
        .cutOff(this.selectedDate)
        .catch(handleError);
      const data = await handleResponse(response);
      this.cutOffDate = data.date;
    },
    async setClient() {
      const response = await API.getClient(this.client.id).catch(handleError);
      if (response.status === 200) {
        this.client.name = response.data.data.name;
        this.client.profile_picture = this.profileImage;
        if (response.data.data.profile_picture.length) {
          this.client.profile_picture = response.data.data.profile_picture;
        }
        this.client.addresses = response.data.data.addresses.data;
      } else {
        showMessage(response.message, "error");
      }
    },
    async fetchInactiveMeals() {
      try {
        const response = await apiClientOrder.getInactiveMeals(
          this.orderDetails.id
        );
        this.inactiveMeals = response.data;
      } catch (error) {
        console.error("Error fetching inactive meals:", error);
      }
    },
    async setOrderDetails() {
      this.loading.orderDetails = true;

      this.orderDetails = { id: this.orderDetails.id };
      const response = await apiClientOrder
        .show({
          clientId: this.client.id,
          orderId: this.orderDetails.id,
          quickView: this.quickViewEnabled,
        })
        .catch((error) =>
          handleError(error, {
            404: {
              name: "Client Profile",
              params: { id: this.client.id },
            },
          })
        );
      this.orderDetails = await handleResponse(response);
      this.loading.orderDetails = false;
    },
    async setOrderAllergensDislikes() {
      if (this.quickViewEnabled) {
        this.orderAllergensDislikes = [];
        return;
      }
      this.loading.orderDetails = true;
      const response = await apiClientOrder
        .allergensDislikes(this.client.id, this.orderDetails.id)
        .catch(handleError);
      this.orderAllergensDislikes = await handleResponse(response);
    },
    async addAllergensDislikesToOrder() {
      this.orderAllergensDislikes.forEach((allergenDislike) => {
        const dayPack = this.dayPacks.find(
          (pack) => pack.id === allergenDislike.order_day_pack_id
        );
        if (!dayPack || !dayPack.dayPackMeals) return;
        this.addAllergenDislikesToDayPack(dayPack, allergenDislike);
      });
      this.loading.orderDetails = false;
    },
    addAllergenDislikesToDayPack(dayPack, allergenDislike) {
      dayPack.dayPackMeals.data.forEach((meal) => {
        if (meal.id === allergenDislike.order_day_pack_meal_id) {
          meal.dayPackMealComponents.data.forEach((component) => {
            if (
              allergenDislike.products.product_id == component.product_id &&
              allergenDislike.products.product_variation_id ===
                component.product_variation_id
            ) {
              component.client_allergens =
                allergenDislike.client_allergens || [];
              component.client_protein_category_dislike =
                allergenDislike.client_protein_category_dislike || [];
              component.client_veg_dislike =
                allergenDislike.client_veg_dislike || [];
              component.client_carb_dislike =
                allergenDislike.client_carb_dislike || [];
            }
          });
        }
      });
    },
    getDislikesAllergensFromDayPackMeals(dayPackMeals) {
      if (!dayPackMeals) return [];
      const combined = {
        client_allergens: dayPackMeals.flatMap((meal) =>
          meal.dayPackMealComponents?.data.flatMap(
            (component) => component.client_allergens || []
          )
        ),
        client_protein_category_dislike: dayPackMeals.flatMap((meal) =>
          meal.dayPackMealComponents?.data.flatMap(
            (component) => component.client_protein_category_dislike || []
          )
        ),
        client_veg_dislike: dayPackMeals.flatMap((meal) =>
          meal.dayPackMealComponents?.data.flatMap(
            (component) => component.client_veg_dislike || []
          )
        ),
        client_carb_dislike: dayPackMeals.flatMap((meal) =>
          meal.dayPackMealComponents?.data.flatMap(
            (component) => component.client_carb_dislike || []
          )
        ),
      };

      for (const key in combined) {
        combined[key] = deduplicateBy(combined[key], "id");
      }

      return combined;
    },
    getSelectedDate() {
      if (
        isDateBetween(
          this.today,
          this.orderDetails.first_delivery_date,
          this.orderDetails.last_delivery_date
        )
      ) {
        return this.today;
      }
      return this.orderDetails.first_delivery_date || this.today;
    },
    async setEvents(selectedDate) {
      this.loading.events = true;
      if (this.events.length > 0) {
        this.events = [];
        this.calendarApi.getEventSources().forEach((eventSource) => {
          eventSource.remove();
        });
        this.calendarApi.addEventSource(this.events);
      }

      if (selectedDate) {
        this.selectedDate = selectedDate;
      } else {
        this.selectedDate = this.getSelectedDate();
      }
      for (let i in this.dayPacks) {
        const dayPack = this.dayPacks[i];
        const eventDate = dayPack.delivery_date;
        this.events.push({
          id: dayPack.id,
          title: dayPack.delivery_status,
          start: eventDate,
          end: eventDate,
          className: getEventBgColor(dayPack.delivery_status),
          extendedProps: {
            status: dayPack.delivery_status,
            dislikesAllergens: this.getDislikesAllergensFromDayPackMeals(
              dayPack.dayPackMeals?.data
            ),
          },
        });
      }

      this.loading.events = false;
      this.setAmendments(this.selectedDate);
    },
    setAmendments(selectedDate) {
      if (!this.amendments[selectedDate]) {
        this.amendments[selectedDate] = {};
        Object.keys(this.mealTypeNames).forEach((item) => {
          this.amendments[selectedDate][item] = [];
        });
      }
    },
    dateClicked(dateObj) {
      if (dateObj.dateStr === this.selectedDate) {
        return;
      }
      this.selectedDate = dateObj.dateStr;
      this.showFood = false;
      this.showDate(this.selectedDate);
    },
    higlightEvent(event) {
      this.events.forEach((ev) => {
        const fullCalendarEvent = this.calendarApi.getEventById(ev.id);
        if (fullCalendarEvent) {
          const defaultClass = getEventBgColor(ev.extendedProps.status);
          fullCalendarEvent.setProp("classNames", [defaultClass]);
        }
      });

      const clickedEvent = this.calendarApi.getEventById(event.id);
      if (clickedEvent) {
        const defaultClass = getEventBgColor(event.extendedProps.status);
        clickedEvent.setProp("classNames", [defaultClass, "outline-dark"]);
      }
    },
    async eventClicked(info) {
      this.showFood = false;
      await this.showEvent(info.event);
    },
    async showEvent(event) {
      if (!event) {
        this.loading.menu = false;
        return;
      }
      this.higlightEvent(event);
      this.loading.menu = true;
      this.selectedEvent = event;
      this.packs.id = event.id;
      this.selectedDate = dateFormat(event.start, "YYYY-MM-DD");
      await this.setMenuItems(event);
      await this.setMenuItemsGroup();
      await this.setDailyMacros();
      this.loading.menu = false;
    },

    async setMenuItems(event) {
      this.menuItems = [];
      this.currentDayPack = this.dayPacks.find((o) => o.id == event.id);
      if (
        (!this.currentDayPack.dayPackMeals?.data ||
          this.currentDayPack.dayPackMeals.data.length === 0) &&
        this.showFood
      ) {
        const response = await apiClientOrder.getDayPackMeals({
          clientId: this.client.id,
          orderId: this.orderDetails.id,
          dayPackId: event.id,
        });
        this.currentDayPack.dayPackMeals = { data: [] };
        const dayPackMeals = await handleResponse(response);
        if (response.status === 200) {
          this.currentDayPack = dayPackMeals;
          const responseAllergensDislikes = await apiClientOrder
            .getDayPackAllergensDislikes({
              clientId: this.client.id,
              orderId: this.orderDetails.id,
              dayPackId: event.id,
            })
            .catch(handleError);
          const allergenDislikesData = await handleResponse(
            responseAllergensDislikes
          );

          if (responseAllergensDislikes.status === 200) {
            allergenDislikesData.forEach((allergenDislike) => {
              this.addAllergenDislikesToDayPack(
                this.currentDayPack,
                allergenDislike
              );
            });
          }

          event.setExtendedProp("status", this.currentDayPack.delivery_status);
          event.setExtendedProp(
            "dislikesAllergens",
            this.getDislikesAllergensFromDayPackMeals(
              this.currentDayPack.dayPackMeals?.data
            )
          );
        }
      }
      this.menuItems = this.currentDayPack.dayPackMeals?.data || [];
      this.mealTypes = [];
      let actualMealTypes = Object.keys(this.mealTypeNames);
      actualMealTypes.forEach((value) => {
        this.mealTypes.push({ id: null, meal_type: value });
      });
    },
    async setMenuItemsGroup() {
      this.menuItemsGroup = {};
      this.setAmendments(this.selectedDate);
      this.menuItems.forEach((menuItem) => {
        if (menuItem.isVisible === undefined) {
          menuItem.isVisible = true;
        }
        const mealType = menuItem.meal_type;

        if (!this.menuItemsGroup[mealType]) {
          this.menuItemsGroup[mealType] = [];
        }

        this.menuItemsGroup[mealType].push(menuItem);
      });
    },
    showDate(date) {
      this.selectedDate = date;
      let eventId = this.getEventIdByDate(date);
      if (eventId) {
        this.initialEventId = eventId;
        this.showEvent(this.calendarApi.getEventById(eventId));
      } else {
        this.currentDayPack = {};
        this.menuItems = [];
      }
    },
    getEventIdByDate(date) {
      let event = this.events.find((x) => x.start === date);
      if (event) {
        return event.id;
      }
      return 0;
    },
    async datesSet(info) {
      if (info.view.type === "dayGridMonth") {
        this.calendarApi.getEventSources().forEach((eventSource) => {
          eventSource.remove();
        });
        this.calendarApi.addEventSource(this.events);
      }
    },
    cardTitle(suffix) {
      let result = "Today's " + suffix;
      if (this.selectedDate && this.selectedDate != this.today) {
        result = dateFormat(this.selectedDate, "Do MMMM") + " " + suffix;
      }
      return result;
    },
    async setDailyMacros() {
      this.dailyMacros = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
      };

      for (let i = 0; i < this.menuItems.length; i++) {
        const mealComponents = this.menuItems[i].dayPackMealComponents.data;

        for (let j = 0; j < mealComponents.length; j++) {
          const variationData = mealComponents[j].productVariation.data;

          this.dailyMacros.calories += parseFloat(variationData.kcal);
          this.dailyMacros.carbs += parseFloat(variationData.carb);
          this.dailyMacros.fat += parseFloat(variationData.fat);
          this.dailyMacros.protein += parseFloat(variationData.pro);
        }
      }
    },

    async deleteMenu(menuItemId) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.formSubmitted) {
            showMessage("Deleting data. Please wait.", "", 1500);
            return;
          }
          this.formSubmitted = true;
          let saveResponse = await API.removeClientDayPackMealMenuItem(
            this.client.id,
            this.orderDetails.id,
            this.packs.id,
            menuItemId
          ).catch(handleError);
          if (saveResponse.data.success) {
            showMessage(saveResponse.data.message, "success");
            await this.setOrder();
            await this.fetchInactiveMeals();
          } else {
            showMessage(saveResponse.data.message, "error");
          }

          this.formSubmitted = false;
        }
      });
    },
    handleAmendmentDelete({ amendmentDate, mealType, index }) {
      const amendedItem = this.amendments[amendmentDate][mealType][index];
      if (amendedItem.id) {
        let menuItem = this.menuItemsGroup[mealType]?.find(
          (el) => el.id == amendedItem.id
        );
        if (menuItem) {
          menuItem.isVisible = true;
        }
      }
      this.amendments[amendmentDate][mealType].splice(index, 1);
    },
    handleMenuDelete(menuItem) {
      if (this.orderCanBeAmended) {
        const amendmentData = {
          action: "delete",
          day_pack_id: menuItem.order_day_pack_id,
          meal_type: menuItem.meal_type,
        };

        this.amendments[this.selectedDate][menuItem.meal_type].push({
          ...menuItem,
          ...amendmentData,
        });
        menuItem.isVisible = false;
      } else {
        this.deleteMenu(menuItem.id);
      }
    },
    handleMenuDeleteNew(product) {
      if (this.orderCanBeAmended) {
        const amendmentData = {
          action: "delete",
          day_pack_id: product.order_day_pack_meal_id,
          meal_type:
            product.meal_type || this.mealTypeNames[product.product_type],
        };
        if (!this.amendments[this.selectedDate]) {
          this.amendments[this.selectedDate] = {};
        }
        if (!this.amendments[this.selectedDate][amendmentData.meal_type]) {
          this.amendments[this.selectedDate][amendmentData.meal_type] = [];
        }
        this.amendments[this.selectedDate][amendmentData.meal_type].push({
          ...product,
          ...amendmentData,
        });

        product.isVisible = false;
      } else {
        this.deleteMenu(product.order_day_pack_meal_id);
      }
    },

    async handleMenuChange(data) {
      if (this.orderCanBeAmended) {
        this.handleAmendmentChange(data);
      } else {
        await this.handleOrderMenuChange(data);
      }
    },
    async handleOrderMenuChange(data) {
      await this.saveMeal(data);
      await this.setOrder();
      await this.fetchInactiveMeals();
    },
    async saveMeal(data) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = {
        meal_type: data.meal_type,
      };

      const meals = data.dayPackMealComponents.data;
      for (let i in meals) {
        const meal = meals[i].productVariation.data;
        const type = meal["product.type"];
        let key = `${type}_variation_id`;
        if (data.meal_type === "breakfast" && type === "sauce") {
          key = "breakfast_sauce_variation_id";
        }
        formData[key] = meal.id;
      }
      let response = { status: 0 };
      if (data.actionType === "Add") {
        response = await API.addMeal(
          this.client.id,
          this.orderDetails.id,
          this.packs.id,
          formData
        ).catch(handleError);
      } else {
        formData["_method"] = "PATCH";
        response = await API.updateMeal(
          this.client.id,
          this.orderDetails.id,
          this.packs.id,
          data.id,
          formData
        ).catch(handleError);
      }
      await handleResponse(response);
      if (response.status === 200) {
        closeModalObject(this.modals.orderMenu);
      }
      this.formSubmitted = false;
    },
    handleAmendmentChange(data) {
      data.action = data.actionType === "Add" ? "create" : "update";
      data.day_pack_id = this.packs.id;
      if (data.id) {
        let menuItem = this.menuItemsGroup[data.meal_type]?.find(
          (el) => el.id == data.id
        );
        if (menuItem) {
          menuItem.isVisible = false;
        }
      }
      this.amendments[this.selectedDate][data.meal_type].push(data);
      closeModalObject(this.modals.orderMenu);
    },
    async handleAmendmentsSave(data) {
      this.$router.push({
        name: "Invoice",
        params: {
          id: data.invoice.data.id,
        },
      });
    },
    async publishOrder() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiOrders
        .publish(this.client.id, this.orderDetails.id)
        .catch(handleError);
      await handleResponse(response);
      await this.setOrderDetails();
      this.formSubmitted = false;
    },
    async freezeDelivery() {
      const response = await apiClientOrderDayPackController
        .freeze(this.client.id, this.orderDetails.id, this.packs.id)
        .catch(handleError);
      await handleResponse(response);
      await this.setOrder();
    },
    async freezeAndMoveDelivery() {
      const response = await apiClientOrderDayPackController
        .freezeAndMove(this.client.id, this.orderDetails.id, this.packs.id)
        .catch(handleError);
      await handleResponse(response);
      await this.setOrder();
    },

    async createDeliveryAddressException(data) {
      const response = await apiClientOrderDayPackController
        .submitDeliveryAddressException(this.client.id, this.packs.id, data)
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        await this.setOrder();
        closeModalObject(this.modals.deliveryAddressException);
      }
    },
    async setOrder() {
      await Promise.all([
        this.setOrderDetails(),
        this.setOrderAllergensDislikes(),
      ]);
      await this.addAllergensDislikesToOrder();
      await this.setEvents(this.selectedDate);
      this.showFullCalendar = true;
      await this.$nextTick();
      this.calendarApi.gotoDate(this.selectedDate);
      this.showDate(this.selectedDate);
    },
    async downloadOrder() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let response = await apiClientOrder
        .downloadPdf(this.client.id, this.orderDetails.id)
        .catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          `order-${this.orderDetails.id}-${dateFormat(
            "current",
            "timestamp"
          )}.pdf`,
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    showOrderMenuModal(data) {
      if (data.dayPackId) {
        this.packs.id = data.dayPackId;
      }
      this.modals.orderMenu.data = data;
      showModalObject(this.modals.orderMenu, this);
    },
    refundRequestSaved() {
      closeModalObject(this.modals.refundRequest);
    },
    async cancelDelivery() {
      const response = await apiClientOrderDayPackController
        .cancel(this.client.id, this.orderDetails.id, this.packs.id)
        .catch(handleError);
      await handleResponse(response);
      await this.setOrder();
    },
    async handleShowFood() {
      this.showFood = true;
      this.showEvent(this.selectedEvent);
    },
  },
};
</script>
<style>
.order-calendar .fc .fc-daygrid-event-harness,
.order-calendar .fc-daygrid-event,
.order-calendar .fc-event .fc-event-main {
  position: initial !important;
  display: block;
}
.order-calendar .fc-daygrid-event-harness {
  display: block;
}
.order-calendar .fc-daygrid-event-harness * {
  overflow: initial !important;
}
.order-calendar .fc-h-event .fc-event-main {
  color: inherit !important;
}
.order-calendar .fc .fc-daygrid-event-harness {
  z-index: 0;
}
.order-calendar .outline-dark {
  outline: 2px solid #344767 !important;
}
</style>
