<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div
          class="text-right col-12 d-flex flex-column justify-content-center"
        >
          <div class="ms-lg-auto me-lg-0 me-auto mt-lg-0">
            <router-link
              :to="{ name: 'Clients List' }"
              class="btn btn-white text-success mb-0"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>

      <loading-spinner :loading="loading.client" />

      <div class="row d-none">
        <div class="col-lg-12">
          <div class="row user-stats">
            <div class="col-lg-3 col-md-6 col-12">
              <mini-statistics-card
                title="Wallet"
                :value="currencyFormat(total_balance)"
                :description="balance"
                :icon="{
                  component: 'fa fa-usd',
                  background: 'bg-gradient-dark',
                  shape: 'rounded-circle',
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow-lg mt-4">
        <div class="card shadow-lg">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto my-auto">
                <div class="avatar avatar-xl position-relative">
                  <img
                    :src="client.profile_picture"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ clientName }}</h5>
                </div>
              </div>
              <div class="mx-auto mt-3 col my-sm-auto ms-sm-auto">
                <div
                  class="nav-wrapper position-relative d-flex justify-content-end"
                >
                  <nav-pills
                    :items="navItems"
                    @set-active-tab="handleActiveTabChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          id="tab-content-profile"
          class="tab-pane fade active show"
          role="tabpanel"
        >
          <div class="card shadow-lg mt-4">
            <div class="card shadow-lg">
              <div class="card-body p-3">
                <router-link
                  :to="{
                    name: 'Client Bag Movements',
                    params: {
                      id: client.id,
                    },
                  }"
                  class="btn mb-0 btn-outline-success btn-sm"
                  >Bag details
                </router-link>
                <argon-button
                  class="ms-3 mb-0 btn-sm"
                  variant="outline"
                  @click="showModalObject(modals.refundRequest)"
                  >Bag Deposit Refund Request</argon-button
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <profile-info-card
                :form-submitted="{
                  changeClientType: formSubmitted.changeClientType,
                }"
                class="h-100"
                title="Profile Information"
                :info="{
                  bio: client.bio,
                  fullName: clientName,
                  phone: client.phone,
                  email: client.email,
                  height: client.height,
                  weight: client.weight,
                  goal: client.goal,
                  salesName: client.sales_name,
                  accountManagerName: client.account_manager_name,
                  referralCode: client.referral_code,
                  type: client.type,
                  location: 'UAE',
                }"
                :action="{
                  route: $router.resolve({
                    name: 'Edit Client',
                    params: {
                      id: client.id,
                    },
                  }).href,
                  tooltip: 'Edit',
                }"
                @change-client-type="changeClientType($event)"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <profile-addresses-card
                class="h-100"
                :addresses="client.addresses"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-4">
              <favourites-card
                class="h-100"
                title="Favourites"
                :items="client.favourites"
                :action="{
                  target: '#favourite-modal',
                  name: 'Add',
                }"
                color="success"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Allergens"
                :items="client.dislikes"
                dislike-type="allergen"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Protein Category Dislikes"
                :items="client.dislikes"
                dislike-type="protein_category"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Veg Dislikes"
                :items="client.dislikes"
                dislike-type="veg"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
            <div class="mt-4 col-12 col-md-6 col-xl-3">
              <dislikes-card
                class="h-100"
                title="Carb Dislikes"
                :items="client.dislikes"
                dislike-type="carb"
                :action="{
                  target: '#dislike-modal',
                  name: 'Add',
                }"
                color="danger"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-orders" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <orders-table
                v-if="activeTab === 'orders' && client.id > 0"
                :show-actions="true"
                :initial-filter="{ client_id: client.id }"
                :show="{ client_id: false, user_id: false }"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-invoices" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <invoices-card
                v-if="activeTab === 'invoices'"
                :initial-filter="{ client_id: client.id }"
                :show-filter="{ client_id: false }"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-remarks" class="tab-pane fade" role="tabpanel">
          <remarks-card
            v-if="activeTab === 'remarks'"
            :client-remarks="clientRemarks"
            :remark-types="clientRemarkTypes"
            :client-id="client.id"
            @created="setClientRemarks()"
            @deleted="setClientRemarks()"
          />
        </div>

        <div
          id="tab-content-consultations"
          class="tab-pane fade"
          role="tabpanel"
        >
          <div class="mt-4 row">
            <div class="col-12">
              <consultation-card
                :consultations="client.consultations"
                :client-id="client.id"
              />
            </div>
          </div>
        </div>
        <div id="tab-content-wallet" class="tab-pane fade" role="tabpanel">
          <div class="mt-4 row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <wallet-transactions
                    v-if="activeTab === 'wallet'"
                    :client-id="client.id"
                    :wallet-balance="balance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="tab-content-refund-requests"
          class="tab-pane fade"
          role="tabpanel"
        >
          <div class="mt-4 row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <refund-requests-card
                    v-if="activeTab === 'refund-requests'"
                    :client-id="client.id"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <dislike-modal
        :client-id="client.id"
        :client-dislikes="client.dislikes"
        @set-client-dislikes="setClientDislikes"
      />
      <favourite-modal
        :client-id="client.id"
        :client-favourites="client.favourites"
        @set-client-favourites="setClientFavourites"
      />
      <refund-request-modal
        v-if="modals.refundRequest.show"
        :modal-id="modals.refundRequest.id"
        :client-id="client.id"
        :initial-data="modals.refundRequest.data"
        dialog-class="modal-xl"
        :bag-deposit-refund="true"
        @saved="refundRequestSaved"
        @close="closeModalObject(modals.refundRequest)"
      />
    </div>
  </main>
</template>

<script>
import MiniStatisticsCard from "../../../examples/Cards/MiniStatisticsCard.vue";
import ProfileInfoCard from "./components/ProfileInfoCard.vue";
import profileImage from "@/assets/img/user-profile-icon.svg";
import API from "@/services/api";
import ApiClientFavourites from "@/services/apiClientFavourites";
import ApiClientDeliveryPreferences from "@/services/apiClientDeliveryPreferences";
import apiClientRemarks from "@/services/apiClientRemarks";
import setTooltip from "@/assets/js/tooltip.js";
import { formatDataToChoicesJs } from "@/assets/js/init-choices";
import { showMessage } from "@/assets/js/show-message";
import LoadingSpinner from "@/components/LoadingSpinner";
import ProfileAddressesCard from "./components/ProfileAddressesCard.vue";
import WalletTransactions from "./components/WalletTransactions";
import InvoicesCard from "@/views/pages/Invoices/components/ListTable";
import DislikesCard from "./components/DislikesCard";
import FavouritesCard from "./components/FavouritesCard";
import RemarksCard from "./components/RemarksCard";
import {
  dateFormat,
  handleError,
  currencyFormat,
  handleResponse,
} from "@/lib/helpers";
import ConsultationCard from "./components/ConsultationCard";
import DislikeModal from "@/views/pages/Clients/components/DislikeModal";
import FavouriteModal from "@/views/pages/Clients/components/FavouriteModal";
import OrdersTable from "@/views/pages/Orders/components/OrdersTable.vue";
import NavPills from "@/components/NavPills.vue";
import RefundRequestsCard from "@/components/RefundRequestsCard.vue";
import apiClient from "@/services/apiClient";
import { closeModalObject, showModal, showModalObject } from "@/lib/bootstrap";
import ArgonButton from "@/components/ArgonButton.vue";
import RefundRequestModal from "@/views/pages/Clients/components/RefundRequestModal.vue";
import apiMiscList from "@/services/apiMiscList";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "Profile",
  components: {
    RefundRequestsCard,
    NavPills,
    OrdersTable,
    DislikeModal,
    FavouriteModal,
    MiniStatisticsCard,
    DislikesCard,
    FavouritesCard,
    WalletTransactions,
    InvoicesCard,
    ProfileInfoCard,
    LoadingSpinner,
    ProfileAddressesCard,
    ConsultationCard,
    ArgonButton,
    RefundRequestModal,
    RemarksCard,
  },
  data() {
    return {
      activeTab: "profile",
      showMenu: false,
      profileImage,
      invoices: [],
      orderId: "",
      client: {
        id: this.$route.params.id,
        sales_name: "",
        account_manager_name: "",
        referral_code: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        birth_date: "",
        bio: "",
        profile_picture: "",
        height: "",
        weight: "",
        goal_weight: "",
        dislikes: [],
        favourites: [],
        addresses: [],
        wallets: [],
        consultations: [],
        type: "",
      },
      clientRemarks: [],
      clientRemarkTypes: [],
      loading: {
        client: true,
        dislikeModal: true,
        invoices: true,
      },
      plansChoices: [],
      orderChoices: [
        {
          id: "1",
          name: "Weekly",
        },
        {
          id: "2",
          name: "Monthly",
        },
      ],
      planVariants: [],
      planVariantsChoices: [],
      plan: {},
      order: {},
      dislike: {},
      dislikeChoices: [],
      dislikeMealTypeChoices: [],
      formSubmitted: {},
      currentTab: "profile",
      total_balance: 0.0,
      balance: "",
      clientDeliveryPreferences: [],
      deliveryDays: [],
      navItems: [],
      modals: {
        refundRequest: {
          show: false,
          id: "refund-request-modal",
          data: {},
        },
      },
    };
  },
  computed: {
    clientName() {
      return this.client.first_name + " " + this.client.last_name;
    },
  },
  async mounted() {
    this.setNavItems();
    setTooltip(this.$store.state.bootstrap);
    this.setDefaultOrder();
    await this.setClient();
    await this.setClientDislikes();
    await this.setClientFavourites();
    await this.setWallets();

    this.loading.client = false;
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.layout = "default";
    body.classList.remove("profile-overview");
  },
  methods: {
    dateFormat,
    currencyFormat,
    showModal,
    closeModalObject,
    showModalObject,
    setNavItems() {
      this.navItems = [
        {
          ref: "profile_tab",
          target: "#tab-content-profile",
          icon: "fa-user-circle",
          text: "Profile",
          activeTab: "profile",
        },
        {
          ref: "orders_tab",
          target: "#tab-content-orders",
          icon: "fa-clipboard-list",
          text: "Orders",
          activeTab: "orders",
        },
        {
          ref: "invoices_tab",
          target: "#tab-content-invoices",
          icon: "fa-file-invoice",
          text: "Invoices",
          activeTab: "invoices",
        },
        {
          ref: "remarks_tab",
          target: "#tab-content-remarks",
          icon: "fa-comments",
          text: "Remarks",
          activeTab: "remarks",
        },
        // {
        //   ref: "consultations_tab",
        //   target: "#tab-content-consultations",
        //   icon: "fa-user-md",
        //   text: "Consultations",
        //   activeTab: "consultations",
        // },
        {
          ref: "wallet_tab",
          target: "#tab-content-wallet",
          icon: "fa-wallet",
          text: "Wallet",
          activeTab: "wallet",
        },
        {
          ref: "refund_requests",
          target: "#tab-content-refund-requests",
          icon: "fa-undo",
          text: "Refund Requests",
          activeTab: "refund-requests",
        },
      ];
    },

    setDefaultOrder() {
      this.order = {
        order_id: "",
        type: "",
        first_delivery_date: "",
        last_delivery_date: "",
        delivery_count: "",
        net_total: "",
        total: "",
        id: "",
        status: "",
      };
    },

    async setDislikeMealTypes() {
      let response = await API.getMealTypes().catch(handleError);
      if (response.status === 200) {
        this.dislikeMealTypeChoices = formatDataToChoicesJs(
          response.data.data,
          "no empty option",
          { id: "value", value: "value", label: "value" }
        );
      }
    },

    async setClient() {
      const appInstance = this;
      await API.getClient(this.client.id)
        .then((res) => {
          this.client = {
            id: res.data.data.id,
            sales_name: res.data.data.clientProfile?.data.sales_name,
            account_manager_name:
              res.data.data.clientProfile?.data.account_manager_name,
            first_name: res.data.data.first_name,
            last_name: res.data.data.last_name,
            bio: res.data.data.bio,
            email: res.data.data.email,
            phone: res.data.data.phone,
            gender: res.data.data.gender,
            dob: res.data.data.clientProfile.data.dob,
            profile_picture: this.profileImage,
            height: res.data.data.clientProfile?.data.height,
            weight: res.data.data.clientProfile?.data.weight,
            goal: res.data.data.clientProfile?.data.goal || "",
            referral_code: res.data.data.referral_code,
            type: res.data.data.type,
            dislikes: [],
            favourites: [],
            plans: [],
            orders: [],
            invoices: [],
            orderDetails: [],
          };
          if (res.data.data.profile_picture.length) {
            this.client.profile_picture = res.data.data.profile_picture;
          }
          this.client.addresses = res.data.data.addresses.data;
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            this.$swal({
              icon: "error",
              title: "Client not found",
              willClose: () => {
                appInstance.$router.push({
                  name: "Clients List",
                });
              },
            });
          } else {
            const response = handleError(err);
            showMessage(response.message, "error");
          }
        });
    },
    async setClientDislikes() {
      const appInstance = this;
      appInstance.client.dislikes = [];
      await API.getClientDislikes(this.client.id)
        .then((res) => {
          for (let i in res.data.data) {
            appInstance.client.dislikes.push({
              id: res.data.data[i].id,
              dislikable_name: res.data.data[i]["dislikable.name"],
              dislikable_id: res.data.data[i]["dislikable_id"],
              dislikable_type: res.data.data[i]["dislikable_type"],
              meal_type: res.data.data[i]["meal_type"],
            });
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientFavourites() {
      const appInstance = this;
      appInstance.client.favourites = [];
      const response = await ApiClientFavourites.get(this.client.id).catch(
        handleError
      );
      if (response.status === 200) {
        this.client.favourites = response.data.data || [];
      } else {
        showMessage(response.message, "error");
      }
    },

    async setWallets() {
      const appInstance = this;
      let params = `client_id=${this.client.id}`;
      await API.getWallets(params)
        .then((res) => {
          appInstance.balance = currencyFormat(res.data.balance);
        })
        .catch((err) => {
          const response = API.handleError(err);
          showMessage(response.message, "error");
        });
    },
    async setClientDeliveryPreferences() {
      const response = await ApiClientDeliveryPreferences.index(
        this.client.id
      ).catch(handleError);
      if (response.status === 200) {
        this.clientDeliveryPreferences = response.data.data;
        for (let i in this.clientDeliveryPreferences) {
          this.clientDeliveryPreferences[i].delivery =
            this.clientDeliveryPreferences[i].delivery == 1;
        }
      } else {
        showMessage(response.message, "error");
      }
    },
    getClientDeliveryDays() {
      let deliveryDays = [];
      for (let i in this.clientDeliveryPreferences) {
        deliveryDays.push({
          day: this.clientDeliveryPreferences[i].day,
          day_text: this.clientDeliveryPreferences[i].day_text,
          delivery: this.clientDeliveryPreferences[i].delivery,
        });
      }
      if (this.plan.id > 0) {
        if (!this.plan.meta.preferred_days) {
          this.plan.meta.preferred_days = [];
        }
        for (let i in deliveryDays) {
          deliveryDays[i].delivery = Object.values(
            this.plan.meta.preferred_days
          ).includes(deliveryDays[i].day.toString());
        }
      }
      return deliveryDays;
    },
    async setClientConsultations() {
      await API.getListsConsultations(this.client.id)
        .then((res) => {
          this.client.consultations = [];
          if (res.data.data.length > 0) {
            this.client.consultations = res.data.data;
          }
        })
        .catch((err) => {
          const response = handleError(err);
          showMessage(response.message, "error");
        });
    },
    refundRequestSaved() {
      closeModalObject(this.modals.refundRequest);
    },
    async changeClientType(type) {
      if (this.formSubmitted.changeClientType) {
        return;
      }
      this.formSubmitted.changeClientType = true;
      const response = await apiClient
        .changeType(this.client.id, {
          type: type,
        })
        .catch(handleError);
      await handleResponse(response);
      if (response.status === 200) {
        this.client.type = type;
        showMessage("Client type changed successfully", "success");
      }
      this.formSubmitted.changeClientType = false;
    },
    async setClientRemarks() {
      const response = await apiClientRemarks
        .index(this.client.id)
        .catch(handleError);
      this.clientRemarks = await handleResponse(response);
    },
    async setClientRemarkTypes() {
      if (this.clientRemarkTypes.length > 0) {
        return;
      }
      const response = await apiMiscList.remarkTypes().catch(handleError);
      this.clientRemarkTypes = formatDataToChoicesJs(
        await handleResponse(response),
        "",
        "enum"
      );
    },
    async handleActiveTabChange(tab) {
      if (tab === "remarks") {
        await this.setClientRemarks();
        await this.setClientRemarkTypes();
      }
      this.activeTab = tab;
    },
  },
};
</script>
<style>
li a {
  cursor: pointer;
}
</style>
